<template>
  <div>
    <Header/>
    <Nav/>
    <div class="topImg">
      <div>المعلومات الأساسية للمعرض</div>
    </div>
    <div class="content">
      <div class="content-top"><span>لصفحة</span> <span>لصفحة الرئيسية > مرك</span><img class="iconhom" src="https://www.cas-expo.org.cn/web/images/home.png" alt=""></div>
        <div class="news-content">
          <!-- <div class="desc-title">المكتب التجاري في نينغشيا: معرض الصين الدولي لتجارة الخدمات يساعد نينغشيا في الترويج للمعرض الصيني العربي</div>
          <div class="desc-content">2023-09-23: وقت النشر   </div>
          <div>
            <img src="./video/news.jpg" alt="">
            <div class="news-tip">قالت نيو شياو تسوي من المكتب التجاري في نينغشيا في مقابلة مع قناة سي جي تي إن العربية إنها تأمل في تعريف مزيد من الناس بالمعرض الصيني العربي من خلال منصة معرض الصين الدولي لتجارة الخدمات، مشيرة إلى أنه سيقام المعرض الصيني العربي في نينغشيا في الفترة من 21 إلى 24 سبتمبر القادم، وتجري جميع الأعمال التحضيرية حاليا بشكل سلس.</div>
          </div> -->
        </div>
    </div>
    <div class="bottomImg">
      <img src="@/assets/img/logofont.png" alt="">
      <div>
        <span>:ICP {{'كل الحقوق محفوظة لدى الأمانة العامة لمعرض الصين والدول العربية'}} </span> <span class="number"> {{'38110050-7'}} </span> <span> {{'نينغشيا رقم تسجيل'}} </span>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Nav from '@/components/Nav'
export default {
  components: {
    Header,
    Nav
  }
}
</script>

<style lang="scss" scoped>
.topImg {
  background: url('~@/assets/img/itemBanner.png');
  min-height: 320px;
  background-size: cover;
  line-height: 320px;
  position: relative;
  div {
    text-align: center;
    font-size: 48px;
    color: #fff;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
.bottomImg {
  background: url('~@/assets/img/itemBottom.png');
  background-size: cover;
  min-height: 287px;
  text-align: center;
  color: rgba(255,255,255,0.65);
  font-family: 'GeezaPro';
  img {
    width: 384px;
    object-fit: cover;
    margin-top: 52px;
    margin-bottom: 57px;
  }
  .number {
    margin-right: 29px;
  }
}
.content {
  position: relative;
  min-height: 738px;
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
  font-family: 'ArialMT';
  .news-content {
    box-shadow: 0px 0px 12px 3px rgba(0,0,0,0.04);
    text-align: center;
    // padding: 24px 100px 51px;
    margin-bottom: 50px;
    background: url('~@/assets/img/itemtext.png');
    height: 631px;
    background-repeat: no-repeat;
    img {
      width: 686px;
      object-fit: cover;
      margin: 0 auto;
      margin-bottom: 25px;
    }
  }
  .desc-title {
    color: #222222;
    font-size: 24px;
    margin-bottom: 18px;
    text-align: center;
  }
  .desc-content {
    color: #999999;
    font-size: 14px;
    text-align: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #EFEFEF;
    margin-bottom: 17px;
  }
  .news-tip {
    font-size: 16px;
    color: #333;
    line-height: 24px;
    text-align: right;
  }
}
.content-top {
  padding-bottom: 20px;
  text-align: right;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 48px;
  color: #333333;
  .iconhom {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
}
.content-bottom {
  margin-top: 48px;
  margin-bottom: 88px;
  padding-top: 27px;
  border-top: 1px solid #EBEBEB;
  .left {
    float: left;
  }
  .right {
    float: right;
  }
}
</style>